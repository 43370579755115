import {
  Avatar,
  Button,
  Col,
  Container,
  Grid,
  Image,
  Row,
  Spacer,
  Text,
} from '@nextui-org/react';
import React from 'react';
import { FaChevronLeft, FaChevronUp, FaRegCommentDots } from 'react-icons/fa';
import { MdAccessible, MdDesignServices } from 'react-icons/md';
import { HiOutlineLightBulb } from 'react-icons/hi';
import TextCard from '../components/TextCard';
import EnumCard from '../components/EnumCard';
import { HashLink } from 'react-router-hash-link';

export default function Delivery() {
  return (
    <div className=''>
      <Container sm css={{ paddingTop: '15vh' }}>
        <Row>
          <HashLink to='/#top'>
            <Avatar
              pointer
              color='$black'
              icon={<FaChevronLeft color='white' />}
              squared
              css={{ backgroundColor: '$primary' }}
            />
          </HashLink>
        </Row>
        <Spacer y={1} />
        <Row>
          <Col>
            <Text h1>Delivery tracking website</Text>
            <Text h4 color='$gray'>
              A resposive package tracking website provide informative deatils
              and SMS notifications.
            </Text>
          </Col>
        </Row>
        <Spacer y={2} />
        <Row>
          <Image
            alt=''
            src='/images/Delivery/Hero.png'
            width='100%'
            height='100%'
          />
        </Row>
        <Spacer y={2} />
        <Grid.Container css={{ rowGap: '1rem' }}>
          <Grid xs={12} sm={4}>
            <Container gap={0}>
              <Row>
                <Col>
                  <Text h4>Duration:</Text>
                  <Spacer y={0.5} />
                  <Text css={{ fontFamily: '$primary' }}>July 2022</Text>
                </Col>
              </Row>
            </Container>
          </Grid>
          <Grid xs={12} sm={4}>
            <Container gap={0}>
              <Row>
                <Col>
                  <Text h4>Project info:</Text>
                  <Spacer y={0.5} />
                  <Text css={{ fontFamily: '$primary' }}>
                    Google UX Design Professional Certificate course prompt
                    project
                  </Text>
                </Col>
              </Row>
            </Container>
          </Grid>
          <Grid xs={12} sm={4}>
            <Container gap={0}>
              <Row>
                <Col>
                  <Text h4>Tools:</Text>
                  <Spacer y={0.5} />

                  <Text css={{ fontFamily: '$primary' }}>Figma</Text>
                </Col>
              </Row>
            </Container>
          </Grid>
        </Grid.Container>

        <Spacer y={2} />
        <Row>
          <Text h2>Overview</Text>
        </Row>
        <Spacer y={2} />
        <Row>
          <Text
            css={{
              fontFamily: '$primary',
              lineHeight: '$xl',
            }}
          >
            The delivery tracking website with SMS notification is the website
            that used for tracking packages from varieties of courier, provide
            tracking details and give an ability to receive SMS notification for
            tracking activities
          </Text>
        </Row>

        <Spacer y={2} />
        <Grid.Container gap={2}>
          <Grid xs={12} md={6}>
            <TextCard
              headline='Role'
              content='As a UX designer of the project.'
            />
          </Grid>
          <Grid xs={12} md={6}>
            <TextCard
              headline='Responsibilities'
              content='Conducting research surveys, information architecture, wireframing, low and high-fidelity prototyping, usability testing, and design iteration.'
            />
          </Grid>
        </Grid.Container>
      </Container>

      {/* User research Section */}

      {/* Design Process */}
      <Container sm css={{ backgroundColor: '', padding: '3rem 0' }}>
        <Container lg gap={2}>
          {/* User Research */}
          <Row align='center'>
            <Avatar
              color={''}
              icon={<FaRegCommentDots size='auto' color='white' />}
              size='xl'
              css={{ backgroundColor: '$green500' }}
            />
            <Spacer x={1} />
            <Col>
              <Text h5 color='$gray'>
                USER INSIGHTS
              </Text>
              <Text h2>Understanding the user</Text>
            </Col>
          </Row>

          <Spacer y={1} />
          <Text
            css={{
              fontFamily: '$primary',
              lineHeight: '$xl',
            }}
          >
            I've published a research survey with questions about background and
            experiences when tracking packages. And receive a total of five
            responses at the end.
          </Text>
          <Spacer y={1} />
          <Text
            css={{
              fontFamily: '$primary',
              lineHeight: '$xl',
            }}
          >
            According to survey responses, the majority of user issues revolve
            around the tracking website's lack of tracking detail, as well as
            users' unwillingness to access the tracking website multiple times
            just to track package activities
          </Text>

          <Spacer y={2} />

          <Text h4>Pain points</Text>

          <Spacer y={2} />

          <Grid.Container gap={2}>
            <Grid xs={12} sm={6}>
              <EnumCard
                number='1'
                content='Users told that the tacking detail is not informative and accurate. Sometime packages delivered to them when no one home!'
                bgColor=''
                header='lack of details'
              />
            </Grid>
            <Grid xs={12} sm={6}>
              <EnumCard
                number='2'
                content='If users want to know the package activities, they’re have enter the website every time just for checking the activities'
                bgColor=''
                header='Inconvenient to use'
              />
            </Grid>
          </Grid.Container>

          <Spacer y={2} />

          <Text h4>Persona</Text>

          <Spacer y={2} />

          <Image
            width='100%'
            height='100%'
            alt=''
            src='images/Delivery/Personas.png'
          />
          <Spacer y={2} />

          <Text h4>Jessica's journey map</Text>

          <Spacer y={2} />

          <Image
            width='100%'
            height='100%'
            alt=''
            src='images/Delivery/User_journey_map.png'
          />
        </Container>
      </Container>
      {/* Wireframing */}
      <Container sm>
        <Grid.Container gap={2} alignItems='center' css={{ rowGap: '3rem' }}>
          <Grid xs={12}>
            <Container fluid gap={0}>
              <Row></Row>

              <Row>
                <Avatar
                  color={''}
                  icon={<HiOutlineLightBulb size='auto' color='white' />}
                  size='xl'
                  css={{ backgroundColor: '$yellow500' }}
                />
                <Spacer x={1} />
                <Col>
                  <Text h5 color='$gray'>
                    IDEATE
                  </Text>
                  <Text h1>Starting the design</Text>
                </Col>
              </Row>
              <Spacer y={1} />

              {/* Sitemap */}
              <Row>
                <Text h4>Sitemap</Text>
              </Row>
              <Spacer y={1} />

              <Row>
                <Text
                  css={{
                    fontFamily: '$primary',
                    lineHeight: '$xl',
                  }}
                >
                  The primary pain points for users are how lack of packages
                  details that expose from website and also the way they can
                  track the details that shoudn’t be access the website for many
                  times just for check package activities
                </Text>
              </Row>
              <Spacer y={2} />

              <Row>
                <Image
                  width='100%'
                  height='100%'
                  alt='Paper wireframes'
                  src='images/Delivery/Sitemap.png'
                />
              </Row>
              <Spacer y={2} />

              {/* Paper wireframes */}

              <Row>
                <Text h4>Paper wireframe</Text>
              </Row>
              <Spacer y={1} />
              <Row>
                <Text
                  css={{
                    fontFamily: '$primary',
                    lineHeight: '$xl',
                  }}
                >
                  Create the initial design by creating paper wireframes in
                  various elements from ideas, then highlighting the elements
                  that appear frequently in the dekstop wireframes then create
                  mobile variants too. In addition, all wireframes would be
                  converted to digital wireframes for subsequent steps.
                </Text>
              </Row>

              <Spacer y={2} />
              <Row>
                <Image
                  width='100%'
                  height='100%'
                  alt='Paper wireframes'
                  src='images/Delivery/Paper_wireframes.png'
                />
              </Row>
              <Spacer y={1} />

              <Row justify='center'>
                <Text h5 color='gray'>
                  Homepage paper wireframe ideation
                </Text>
              </Row>

              <Spacer y={2} />

              <Row>
                <Text h4>Digital wireframe</Text>
              </Row>
              <Spacer y={1} />
              <Row>
                <Text
                  css={{
                    fontFamily: '$primary',
                    lineHeight: '$xl',
                  }}
                >
                  From paper wireframes to a digital one, low-fi wireframes will
                  make user more transparent how the tracking process will
                  occur.
                </Text>
              </Row>
              <Spacer y={2} />

              <Row>
                <Image
                  width='100%'
                  height='100%'
                  alt='Paper wireframes'
                  src='images/Delivery/Homepage_digital.png'
                />
              </Row>

              <Row>
                <Image
                  width='100%'
                  height='100%'
                  alt='Paper wireframes'
                  src='images/Delivery/Mobile_variants_digital_wireframes.png'
                />
              </Row>

              <Spacer y={2} />

              <Row>
                <Text h4>Usability study</Text>
              </Row>
              <Spacer y={2} />

              <Row>
                <Image
                  width='auto'
                  src='images/Delivery/Low-fi_prototype.png'
                />
              </Row>
              <Spacer y={1} />

              <Row justify='center'>
                <Text h5 color='gray'>
                  Low-fidelity prototype
                </Text>
              </Row>
              <Spacer y={2} />

              <Row>
                <Text
                  css={{
                    fontFamily: '$primary',
                    lineHeight: '$xl',
                  }}
                >
                  After create low-fi prototype, I've conducted usability study
                  to evalute initial solution and design. These are the
                  parameters of this usability study:
                </Text>
              </Row>

              <Spacer y={2} />

              <Grid.Container gap='2'>
                <Grid xs={12} sm={6}>
                  <TextCard
                    content='Unmoderated usability study'
                    headline='Study type'
                  />
                </Grid>
                <Grid xs={12} sm={6}>
                  <TextCard headline='Location' content='Remote testing' />
                </Grid>
                <Grid xs={12} sm={6}>
                  <TextCard headline='Particiapants' content='5 participants' />
                </Grid>
                <Grid xs={12} sm={6}>
                  <TextCard headline='Length' content='10-15 minutes' />
                </Grid>
              </Grid.Container>

              <Spacer y={1} />

              <Row>
                <Text h5>Main finding</Text>
              </Row>
              <Spacer y={1} />
              <Row>
                <Text
                  css={{
                    fontFamily: '$primary',
                    lineHeight: '$xl',
                  }}
                >
                  From usability study, I summarized and main findings are:
                </Text>
              </Row>
              <Spacer y={1} />

              <Row>
                <Container
                  gap={0}
                  css={{
                    borderRadius: '$base',
                  }}
                >
                  <Grid.Container gap={2}>
                    <Grid xs={12} sm={6}>
                      <EnumCard
                        number='1'
                        header='Navigation'
                        content='Users think are took a long time to find “my packages” when add a package to their account.'
                      />
                    </Grid>
                    <Grid xs={12} sm={6}>
                      <EnumCard
                        number='2'
                        header='UX writing'
                        content='Users misunderstand and don’t know what some button is main flow can do and what for because misleading words.'
                      />
                    </Grid>
                  </Grid.Container>

                  {/* Low-fidelity Finding */}
                </Container>
              </Row>

              <Spacer y={2} />
            </Container>
          </Grid>
        </Grid.Container>
      </Container>

      <Spacer y={4} />
      {/* Mockup & Design System  */}
      <Container sm>
        <Row justify='center'>
          <Avatar
            color={''}
            icon={<MdDesignServices size='auto' color='white' />}
            size='xl'
            css={{ backgroundColor: '$red600' }}
          />
        </Row>
        <Spacer y={1} />

        <Row justify='center'>
          <Text h5 color='$gray'>
            REFINE THE DESIGN
          </Text>
        </Row>
        <Row justify='center'>
          <Text h1 css={{ textAlign: 'center' }}>
            Mockups & design system
          </Text>
        </Row>
        <Spacer y={2} />

        <Row justify='center'>
          <Image width='100%' src='images/Delivery/DesignSystem1.png' />
        </Row>

        <Row justify='center'>
          <Image width='100%' src='images/Delivery/DesignSystem2.png' />
        </Row>

        <Spacer y={2} />

        <Row>
          <Text>
            From the first usability study, I received a lot of feedback and
            insights on how to make the product more practical and address
            users' pain points. The first is the home page layout, which makes
            the tracking packages component unnoticeable to users at first
            glance.
          </Text>
        </Row>
        <Spacer y={2} />
        <Row justify='center'>
          <Image width='100%' src='images/Delivery/Update1.png' />
        </Row>
        <Spacer y={2} />
        <Row>
          <Text>
            Next, the navigation complexity on the way to access “my packages”
            in user account, I revised the flow, the menu location to more easy
            to access and update UX writing to more clarification.
          </Text>
        </Row>
        <Spacer y={2} />

        <Row justify='center'>
          <Image width='100%' height='100%' src='images/Delivery/Update2.png' />
        </Row>
        <Spacer y={2} />

        <Row justify='center'>
          <Image width='100%' height='100%' src='images/Delivery/Update3.png' />
        </Row>

        <Spacer y={2} />
        <Row justify='center'>
          <Text h5>Mobile variants</Text>
        </Row>
        <Spacer y={2} />

        <Row justify='center'>
          <Image
            width='100%'
            height='100%'
            src='images/Delivery/Mobile_variants.png'
          />
        </Row>
        <Spacer y={2} />

        <Row justify='center'>
          <Text h5>High-fidelity prototype</Text>
        </Row>
        <Spacer y={2} />

        <Row>
          <Text>
            After apply solution for users feedback from previous usability
            study, I added visual design, make the prototype nearly the final
            product and find the problems that should be fixed before finalized.
          </Text>
        </Row>

        <Spacer y={2} />

        <Row justify='center'>
          <Image
            width='100%'
            height='100%'
            src='images/Delivery/Hi-fi_prototype.png'
          />
        </Row>
      </Container>
      {/* Ending */}

      <Spacer y={2} />

      {/* Accessibilities consideration */}
      <Container sm>
        <Row align='center'>
          <Avatar
            color={''}
            icon={<MdAccessible size='auto' color='white' />}
            size='xl'
            css={{ backgroundColor: '$blue400' }}
          />

          <Spacer x={1} />

          <Text h3> Accessibilities consideration</Text>
        </Row>

        <Spacer y={1} />

        <Row>
          <Grid.Container gap={3}>
            <Grid xs={12} sm={6}>
              <EnumCard
                number='1'
                header='Iconography'
                content='Using iconography to help people easily understand what element is and what functional it can be action.'
              />
            </Grid>
            <Grid xs={12} sm={6}>
              <EnumCard
                number='2'
                header='Visual hearachy'
                content='I used heading with differences sized and color of text for clear visual hierarchy.'
              />
            </Grid>
          </Grid.Container>
        </Row>
      </Container>

      <Spacer y={2} />

      {/* Things learned */}

      <Container sm>
        <Text h3>Takeaways</Text>
        <Spacer y={2} />

        <Text h4> Impact</Text>
        <Spacer y={1} />

        <Text
          css={{
            fontFamily: '$primary',
            lineHeight: '$xl',
          }}
        >
          Since the beginning, I've learned about the design process and the
          steps that must be taken to ensure that the application is truly
          designed to solve the user's pain point. Iteration also improves the
          design based on user feedback and prevents unexpected error flow when
          using the app.
        </Text>
        <Spacer y={2} />

        <Text h4> What I learned</Text>
        <Spacer y={1} />

        <Grid.Container gap={2}>
          <Grid xs={12} sm={6}>
            <EnumCard
              number='1'
              header='Website design'
              content={
                'It' +
                "'" +
                's the first time that I design website. The design guidelines and topics of concern differ greatly from those of mobile application design.'
              }
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <EnumCard
              number='2'
              header='Reponsive design'
              content={
                "Since website are not only be used on desktop devices only, I've to design to compatible with small devices like smartphone and tablet to make all devices experiences all the same"
              }
            />
          </Grid>
          <Grid xs={12}>
            <EnumCard
              number='3'
              header='Critical feedbacks'
              content={
                "In this project, I got a lot of feedback about UX writing of action buttons. Many participants said they didn't understand the button do and what till happend. That's reason is have a chance to prevent users to accomplish their goal when using website."
              }
            />
          </Grid>
        </Grid.Container>

        <Spacer y={2} />

        <Row justify='center'>
          <HashLink to='/delivery#top'>
            <Button id='backToTop' auto light iconRight={<FaChevronUp />}>
              Back to top
            </Button>
          </HashLink>
        </Row>
        <Spacer y={2} />
      </Container>
    </div>
  );
}
