import './css/home.css';
import './css/box.css';
import React from 'react';
import {
  Spacer,
  Grid,
  Container,
  useTheme,
  Text,
  Row,
  Button,
} from '@nextui-org/react';
import Header from '../components/Header';
import { useTransform, useViewportScroll, motion } from 'framer-motion';
import { FaChevronUp } from 'react-icons/fa';
import ExperienceProjectHero from '../components/ExperienceProjectHero';
import InternshipContainer from '../components/InternshipContainer';
import { HashLink } from 'react-router-hash-link';

function Home() {
  const { theme } = useTheme();
  const { scrollYProgress } = useViewportScroll();
  const scale = useTransform(scrollYProgress, [0, 1], [1, 1.5]);

  return (
    <>
      {/* Header */}
      <Container
        sm
        gap={0}
        style={{ backgroundColor: '$bedge', maxWidth: '100vw' }}
      >
        <motion.div
          className='d-lg-block d-none'
          style={{ scale, backgroundColor: '$bedge', paddingBottom: '2rem' }}
        >
          <Container fluid css={{ backgroundColor: '$bedge' }}>
            <Grid.Container
              alignItems='center'
              css={{
                minHeight: '100vh',
                maxWidth: '70rem',
                padding: '0 1rem',
                zIndex: '0',
                scaleY: scrollYProgress,
                margin: '0 auto',
              }}
            >
              <Header />
            </Grid.Container>
          </Container>
        </motion.div>

        <div
          className='d-lg-none d-block'
          style={{
            scale,
            backgroundColor: '$bedge',
          }}
        >
          <Container
            sm
            css={{ backgroundColor: '$bedge', paddingBottom: '3rem' }}
          >
            <Grid.Container
              alignItems='center'
              css={{
                minHeight: '100vh',
                maxWidth: '80rem',
                padding: '0 1rem',
                zIndex: '0',
                scaleY: scrollYProgress,
                margin: '0 auto',
              }}
            >
              <Spacer y={5} />
              <Header />
            </Grid.Container>
          </Container>
        </div>
      </Container>
      <Spacer y={4} />


      {/* Experiences */}

      {/* Case Study project */}

      <Container md id='casestudy'>
        <Row>
          <Text h1 className='daily-ui-topic'>
            Cases study 🔎
          </Text>
        </Row>
      </Container>
      <Spacer y={2} />
      <Container md>
        <Grid.Container justify='center' gap={2}>
          <ExperienceProjectHero
            type='complete'
            imgSrc='/images/Delivery/Hero.png'
            header='Devliery tracking website'
            route='/delivery'
            subheader='Google UX Design Professional Certificate Project'
            content='A responsive website with detailed tracking packages from delivery couriers and SMS notifications to keep users up to date on tracking activities.'
            period='July 2022'
          />

          {/* Menual */}
          <ExperienceProjectHero
            type='complete'
            imgSrc='/images/Menual/Menual_Hero.png'
            header='Menual'
            route='/menual'
            subheader='Google UX Design Professional Certificate Project'
            content='Food delivery application that can customize meals and orders in order to make user orders as similar to restaurant orders as possible.'
            period='July 2022'
          />

          {/* RaisenAsk */}
          <ExperienceProjectHero
            type='complete'
            imgSrc='/images/raisenAskNew.png'
            header='RaisenAsk'
            route='/raisenask'
            subheader='Senior project'
            Award='Best Popular Project'
            content='Developed a platform for interactive online video conferencing due to the COVID-19 pandemic in a group of 3 from scratch. Be a UX/UI designer and front-end developer for the team.'
            period='May 2022'
          />
          {/* DailyTrip */}

          <ExperienceProjectHero
            type='complete'
            imgSrc='/images/DailyTrip.png'
            header='DailyTrip'
            route='/DailyTrip'
            subheader='Internship assignment'
            content='The design is based on the internship skill test task requirements. The application is for trip planning and location discovery. My role as a UI/UX Designer begins with user research, prototyping, and usability testing.'
            period='April 2021'
          />

          {/* 9Facesushi */}

          {/* <ExperienceProjectHero
            type='complete'
            imgSrc='/images/9Facesushi.png'
            header='9Facesushi'
            subheader='Work'
            route='/9facesushi'
            content="A group of two freelancers worked from a small restaurant (UI Designer and Front-end Developer). The website's content is about a restaurant's food menu."
            period='November 2020'
          /> */}

          {/* Wongnai Redesign */}

          {/* <ExperienceProjectHero
            type='complete'
            imgSrc='/images/wongnai.png'
            header='Wongnai redesign app'
            subheader='Case study'
            route='/wongnai'
            period='November 2020'
            content="As one of the app's users, I've discovered issues with the app's design. The design does not provide the desired user experience. I created and implemented a new design for the app."
          /> */}
        </Grid.Container>
      </Container>

      <Container md gap={2}>
        {/* Experiences */}

        <Row>
          <Text h1 className='daily-ui-topic'>
            Internship 🏃‍♂️
          </Text>
        </Row>

        <InternshipContainer imgSrc='/images/AgnosLogoWide.png' />
      </Container>

      <Spacer y={2} />

      <Row justify='center'>
        <HashLink smooth to='/#top'>
          <Button id='backToTop' auto light iconRight={<FaChevronUp />}>
            Back to top
          </Button>
        </HashLink>
      </Row>
      <Spacer y={2} />

      <Row fullWidth justify='center'>
        <Text color='$gray' css={{ textAlign: 'center' }}>
          Developed by Kamin Prakob, updated 2022.
        </Text>
      </Row>
      <Spacer y={0.5} />
      <Row fullWidth justify='center'>
        <Text color='$gray' css={{ textAlign: 'center' }}>
          kkamin.p@gmail.com - (+66) 83 315 1921
        </Text>
      </Row>
      <Spacer y={2} />
    </>
  );
}

export default Home;
