import React from 'react';
import {
  Text,
  Spacer,
  Grid,
  Col,
  Row,
  Avatar,
  Tooltip,
  useTheme,
} from '@nextui-org/react';
import {
  FaLinkedinIn,
  FaGithub,
  FaLine,
  FaPhoneAlt,
  FaMailBulk,
} from 'react-icons/fa';

export default function Header() {
  const { theme } = useTheme();
  return (
    <>
      <Grid xs={12} sm={6}>
        <img
          src='/images/kamin2022.jpg'
          alt='profile'
          style={{
            borderRadius: '10rem',
            maxWidth: '30rem',
            minWidth: '20rem',
          }}
        />
      </Grid>

      <Grid xs={12} sm={6}>
        <Col>
          <Row>
            <Text
              h1
              style={{
                marginTop: '1rem',
              }}
            >
              Kamin Prakob's Portforlio
            </Text>
          </Row>
          <Spacer y={0.5} />
          <Row>
            <Col>
              <Text
                color='$gray'
                h4
                style={{
                  lineHeight: theme.lineHeights.lg,
                  letterSpacing: theme.letterSpacings.tight,
                }}
              >
                Graduated student, Computer Engineering, KMUTT.
              </Text>
            </Col>
          </Row>
          <Spacer y={1} />
          {/* Description */}

          <Row>
            <Text
              css={{
                lineHeight: theme.lineHeights.lg.value,
                letterSpacing: theme.letterSpacings.tight,
                fontFamily: 'DM sans',
              }}
            >
              This website displays my related user experience and user
              interface experiences. It is also expected that the design will
              minimize pain points and improve users' daily lives!
            </Text>
          </Row>
          <Spacer y={1} />

          <Grid.Container gap={1}>
            <Grid xs={12} sm={6}>
              <FaPhoneAlt size={18} />
              <Spacer x={1} />
              <Text b css={{ fontFamily: 'DM sans' }}>
                (+66) 83-315-1921
              </Text>
            </Grid>
            <Grid xs={12} sm={6}>
              <FaMailBulk size={18} />
              <Spacer x={1} />
              <Text b css={{ fontFamily: 'DM sans' }}>
                kkamin.p@gmail.com
              </Text>
            </Grid>
          </Grid.Container>

          <Row align='center' gap={0}>
            <Spacer x={1} />
          </Row>
          {/* Social Media */}
          <Spacer y={1} />
          <Row>
            <a
              style={{ textDecoration: 'none' }}
              href='https://www.linkedin.com/in/kaminp/'
            >
              <Avatar
                pointer='true'
                color='rgb(20, 30, 39)'
                squared
                icon={<FaLinkedinIn color='white' />}
                css={{ backgroundColor: '$primary' }}
              />
            </a>
            <Spacer x={0.5} />

            <a
              style={{ textDecoration: 'none', color: 'black' }}
              href='https://github.com/kaminpum'
            >
              <Avatar
                pointer='true'
                squared
                icon={<FaGithub color='white' />}
                color='rgb(20, 30, 39)'
                css={{ backgroundColor: '$primary' }}
              />
            </a>

            <Spacer x={0.5} />

            <Tooltip
              trigger='click'
              placement='bottom'
              content={
                <center>
                  <Text color='$black' b>
                    Add Me!
                  </Text>
                  <img
                    src={'../images/LineID.jpg'}
                    alt='Line ID'
                    style={{ width: '10rem' }}
                  />
                </center>
              }
            >
              <Avatar
                pointer='true'
                squared
                icon={<FaLine color='white' />}
                color='rgb(20, 30, 39)'
                css={{ backgroundColor: '$primary' }}
              />
            </Tooltip>
          </Row>
        </Col>
      </Grid>
    </>
  );
}
