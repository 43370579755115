import {
  Button,
  Col,
  Container,
  Grid,
  Image,
  Row,
  Spacer,
  Text,
  useTheme,
} from '@nextui-org/react';
import React from 'react';
import { FaChevronRight } from 'react-icons/fa';
import { HashLink } from 'react-router-hash-link';

export default function ExperienceProjectHero(props) {
  const {
    imgSrc,
    header,
    subheader,
    content,
    route,
    imgAlt,
    type,
    period,
    Award,
  } = props;
  const { theme } = useTheme();
  if (type === 'complete') {
    return (
      <>
        {/* <RelatedProject /> */}

        <Grid xs={12}>
          <Grid.Container gap={2} justify='center' alignItems='center'>
            <Grid xs={12} sm={6}>
              <Image
                src={imgSrc}
                alt={imgAlt}
                width='100%'
                height='auto'
                css={{ borderRadius: '3rem' }}
              />
            </Grid>

            <Grid xs={12} sm={6} justify='flex-end'>
              <Container>
                <Col>
                  <Text h5 color='$gray'>
                    {subheader}
                  </Text>
                  <Text h2>{header}</Text>

                  <Spacer y={1} />
                  <Text
                    size={18}
                    css={{
                      letterSpacing: theme.letterSpacings.wide,
                      fontFamily: '$primary',
                    }}
                  >
                    {content}
                  </Text>
                  <Spacer y={2} />
                  <Row justify='space-between' align='center'>
                    <Col>
                      <Text color='$gray'>{period}</Text>
                    </Col>
                    <Col>
                      <Row justify='flex-end'>
                        <HashLink to={route + '#top'}>
                          <Button size='lg' auto>
                            Read more
                            <Spacer x={0.5} />
                            <FaChevronRight />
                          </Button>
                        </HashLink>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Container>
            </Grid>
            <Spacer y={1} />
          </Grid.Container>
        </Grid>
      </>
    );
  } else if (type === 'progress') {
    return (
      <Grid xs={12}>
        <Grid.Container
          gap={2}
          justify='center'
          alignItems='center'
          css={{ padding: '0 2rem' }}
        >
          <Grid xs={12} sm={6}>
            <Image
              src={imgSrc}
              alt={imgAlt}
              width='100%'
              height='auto'
              css={{ borderRadius: '3rem' }}
            />
          </Grid>
          <Grid xs={12} sm={6} justify='flex-end'>
            <Container>
              <Col>
                <Text h5 color='$gray'>
                  {subheader}
                </Text>
                <Text h2>{header}</Text>
                <Spacer y={1} />
                <Text
                  size={18}
                  css={{
                    letterSpacing: theme.letterSpacings.wide,
                    fontFamily: '$primary',
                  }}
                >
                  {content}
                </Text>
                <Spacer y={2} />
                <Row justify='flex-end'>
                  <Button disabled size='lg' auto>
                    Currently in progress
                    <Spacer x={0.5} />
                  </Button>
                </Row>
              </Col>
            </Container>
          </Grid>
          <Spacer y={1} />
        </Grid.Container>
      </Grid>
    );
  }
}
