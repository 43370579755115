import { useState } from 'react';
import '../pages/css/Navbar.css';
import {
  Avatar,
  Button,
  Container,
  Modal,
  Popover,
  Row,
  Spacer,
  Text,
} from '@nextui-org/react';
import React from 'react';
import { useTheme as useNextTheme } from 'next-themes';
import { useTheme } from '@nextui-org/react';
import { RiSunFill, RiMoonClearFill } from 'react-icons/ri';
import { FaPaperclip } from 'react-icons/fa';
import { stack as Menu } from 'react-burger-menu';
import { IoMenu } from 'react-icons/io5';
import { HashLink } from 'react-router-hash-link';
import { RiCloseCircleFill } from 'react-icons/ri';
import { Link } from 'react-router-dom';

function NavBar() {
  const [visible, setVisible] = useState(true);
  const { setTheme } = useNextTheme();
  const { isDark, type } = useTheme();

  const toggleTheme = () => {
    setTheme(isDark ? 'light' : 'dark');
  };

  var styles = {
    bmBurgerButton: {
      position: 'fixed',
      width: '2rem',
      height: '2rem',
      left: '36px',
      top: '36px',
    },
    bmBurgerBars: {
      background: '#373a47',
    },
    bmBurgerBarsHover: {
      background: '#a90000',
    },
    bmCrossButton: {
      height: '3rem',
      width: '3rem',
      padding: '0.7rem 0 0 0rem',
    },
    bmCross: {
      // background: '#ffffff',
    },
    bmMenuWrap: {
      position: 'fixed',
      height: '100%',
    },
    bmMenu: {
      background: 'rgb(20, 30, 39)',
      padding: '4rem 2rem',
      fontSize: '1.5rem',
    },
    bmMorphShape: {
      fill: '#373a47',
    },
    bmItemList: {
      color: '#ffffff',
      padding: '0.8em',
    },
    bmItem: {
      display: 'inline-block',
    },
    bmOverlay: {
      background: 'rgba(0, 0, 0, 0.7)',
    },
  };

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -120;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  };

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [setModalOpen, setSetModalOpen] = useState(false);
  const closeHandler = () => {
    setSetModalOpen(false);
  };

  const ResumeModalMobile = () => {
    return (
      <Modal closeButton open={setModalOpen} onClose={closeHandler}>
        <Modal.Header>
          <Text h5>Select role</Text>
        </Modal.Header>
        <Modal.Body>
          <Container gap={2}>
            <Row gap={2}>
              <Link
                to={{
                  pathname:
                    'https://drive.google.com/file/d/12fSFt-aCxgd5updKOyGjbvm5bQ2cwsbW/view?usp=sharing',
                }}
                target='_blank'
              >
                <Button
                  bordered
                  auto
                  color={'$primary'}
                  icon={<FaPaperclip />}
                  css={{
                    fontSize: '1rem',
                    letterSpacing: '0.05rem',
                  }}
                >
                  UX Designer
                </Button>
              </Link>
            </Row>

            <Row gap={2}>
              <Link
                to={{
                  pathname:
                    'https://drive.google.com/file/d/1ZgCjhTVL4UUVJK0vtBA5ygHUG1282zCN/view?usp=sharing',
                }}
                target='_blank'
              >
                <Button
                  bordered
                  auto
                  color={'$primary'}
                  icon={<FaPaperclip />}
                  css={{
                    fontSize: '1rem',
                    letterSpacing: '0.05rem',
                  }}
                >
                  Front-end Developer
                </Button>
              </Link>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    );
  };

  return (
    <nav>
      <div
        className='app__navbar d-none d-md-block'
        style={{
          top: visible ? '0' : '-150px',
          backdropFilter: 'blur(7px)',
          WebkitBackdropFilter: 'blur(7px)',
        }}
      >
        <Container
          sm
          gap={0}
          responsive
          css={{
            width: '100%',
          }}
        >
          <Row justify='space-between' alignItems='center'>
            <Container gap={0}>
              <Row>
                <HashLink
                  smooth
                  to={`/#top`}
                  style={{ textDecoration: 'none' }}
                >
                  <Button
                    auto
                    light
                    css={{
                      fontSize: '1rem',
                      textTransform: 'uppercase',
                      fontWeight: '$bold',
                      letterSpacing: '0.05rem',
                    }}
                  >
                    Home
                  </Button>
                </HashLink>
                <HashLink
                  smooth
                  to='/#casestudy'
                  style={{ textDecoration: 'none' }}
                  scroll={(el) => scrollWithOffset(el)}
                >
                  <Button
                    auto
                    light
                    css={{
                      fontSize: '1rem',
                      textTransform: 'uppercase',
                      fontWeight: '$bold',
                      letterSpacing: '0.05rem',
                    }}
                  >
                    work
                  </Button>
                </HashLink>

                <HashLink
                  smooth
                  to={`/aboutme#top`}
                  style={{ textDecoration: 'none' }}
                >
                  <Button
                    auto
                    light
                    css={{
                      fontSize: '1rem',
                      textTransform: 'uppercase',
                      fontWeight: '$bold',
                      letterSpacing: '0.05rem',
                      // color: "$primary",
                      // border: "2px solid $primary",
                    }}
                  >
                    about me
                  </Button>
                </HashLink>
              </Row>
            </Container>
            <Spacer x={1} />

            <Popover isBordered disableShadow>
              <Popover.Trigger>
                <Button
                  bordered
                  auto
                  color={'$primary'}
                  icon={<FaPaperclip />}
                  css={{
                    fontSize: '1rem',
                    letterSpacing: '0.05rem',
                  }}
                >
                  Resume
                </Button>
              </Popover.Trigger>
              <Popover.Content>
                <Container gap={2} css={{ padding: '1rem' }}>
                  <Row justify='center'>
                    <Text h5 css={{ textAlign: 'center' }}>
                      Role
                    </Text>
                  </Row>
                  <Spacer y={0.5} />
                  <Row gap={2}>
                    <a href='https://drive.google.com/file/d/12fSFt-aCxgd5updKOyGjbvm5bQ2cwsbW/view?usp=sharing'>
                      <Button flat color={'gradient'}>
                        <Text color='white'> UX designer</Text>
                      </Button>
                    </a>
                  </Row>
                  <Spacer y={0.5} />
                  <Row gap={2}>
                    <a
                      href='
                          https://drive.google.com/file/d/1ZgCjhTVL4UUVJK0vtBA5ygHUG1282zCN/view?usp=sharing'
                    >
                      <Button flat color={'gradient'}>
                        <Text color='white'> Front-end Developer</Text>
                      </Button>
                    </a>
                  </Row>
                </Container>
              </Popover.Content>
            </Popover>

            <Spacer x={1} />

            {isDark ? (
              <Avatar
                color=''
                squared
                pointer
                checked={isDark}
                onClick={() => toggleTheme()}
                icon={<RiSunFill color='white' />}
                css={{ backgroundColor: 'rgb(32, 50, 57)' }}
              />
            ) : (
              <Avatar
                color=''
                squared
                pointer
                bordered
                checked={isDark}
                onClick={() => toggleTheme()}
                icon={<RiMoonClearFill color='white' />}
                css={{ backgroundColor: '$primary' }}
              />
            )}
          </Row>
        </Container>
      </div>

      <Container
        sm
        className='d-block d-md-none '
        gap={0}
        css={{
          backgroundFilter: 'blur(5px)',
          width: '100%',
        }}
      >
        <Row>
          <Menu
            styles={styles}
            right
            customBurgerIcon={<IoMenu />}
            customCrossIcon={<RiCloseCircleFill color='white' />}
            isOpen={isMenuOpen}
            onOpen={() => setIsMenuOpen(!isMenuOpen)}
            onClose={() => setIsMenuOpen(false)}
            elastic
            // noOverlay
          >
            <Row justify='flex-end'>
              <HashLink
                to={'/#top'}
                id='home'
                onClick={() => setIsMenuOpen(false)}
              >
                <Text h3 color='white' css={{ textAlign: 'end' }}>
                  Home
                </Text>
              </HashLink>
            </Row>
            <Spacer y={1} />
            <Row>
              <HashLink
                to={'/#casestudy'}
                id='work'
                smooth
                onClick={() => setIsMenuOpen(false)}
                scroll={(el) => scrollWithOffset(el)}
              >
                <Text h3 color='white' css={{ textAlign: 'end' }}>
                  Work
                </Text>
              </HashLink>
            </Row>

            <Spacer y={1} />
            <Row>
              <HashLink
                smooth
                to={'/aboutme#top'}
                id='aboutme'
                onClick={() => setIsMenuOpen(false)}
              >
                <Text h3 color='white' css={{ textAlign: 'end' }}>
                  About me
                </Text>
              </HashLink>
            </Row>
            <Spacer y={1} />

            <Container gap={0} alignItems='flex-end'>
              <Text color='white' css={{ textAlign: 'start' }}>
                Resume
              </Text>
              <Spacer y={0.5} />
              <a href='https://drive.google.com/file/d/12fSFt-aCxgd5updKOyGjbvm5bQ2cwsbW/view?usp=sharing'>
                <Button bordered fullWidth onClick={() => setModalOpen(true)}>
                  <Text color='white' css={{ textAlign: 'end' }}>
                    UX designer
                  </Text>
                </Button>
              </a>

              <Spacer y={0.5} />
              <a
                href='
                          https://drive.google.com/file/d/1ZgCjhTVL4UUVJK0vtBA5ygHUG1282zCN/view?usp=sharing'
              >
                <Button fullWidth bordered onClick={() => setModalOpen(true)}>
                  <Text color='white' css={{ textAlign: 'end' }}>
                    Front-end Developer
                  </Text>
                </Button>
              </a>
            </Container>

            <Spacer y={1} />

            <Row fullWidth justify='flex-end'>
              {isDark ? (
                <Button onClick={() => toggleTheme()}>
                  <Row>
                    <RiSunFill color='white' size={20} />
                    <Spacer x={1} />
                    <Text h5 color='white'>
                      Light mode
                    </Text>
                  </Row>
                </Button>
              ) : (
                <Button onClick={() => toggleTheme()}>
                  <Row>
                    <RiMoonClearFill color='white' size={20} />
                    <Spacer x={1} />
                    <Text h5 color='white'>
                      Dark mode
                    </Text>
                  </Row>
                </Button>
              )}
            </Row>
          </Menu>
        </Row>
      </Container>
    </nav>
  );
}

export default NavBar;
