import { Avatar, Card, Container, Row, Spacer, Text } from '@nextui-org/react';
import React from 'react';

export default function EnumCard(props) {
  const { number, content, bgColor, header } = props;
  return (
    <Card shadow='false' bordered>
      <Container gap={0}>
        <Row justify='center'>
          <Avatar
            text={number}
            textColor='$white'
            size='md'
            css={{
              backgroundColor: bgColor,
            }}
          />
        </Row>
        {header && (
          <>
            <Spacer y={1} />

            <Row justify='center'>
              <Text h5>{header}</Text>
            </Row>
          </>
        )}

        <Spacer y={1} />
        <Row justify='center'>
          <Text
            align='center'
            css={{
              fontFamily: '$primary',
              lineHeight: '$xl',
              textAlign: 'center',
            }}
          >
            {content}
          </Text>
        </Row>
      </Container>
    </Card>
  );
}
