import {
  Button,
  Card,
  Col,
  Container,
  Grid,
  Image,
  Row,
  Spacer,
  Text,
} from '@nextui-org/react';
import './css/aboutme.css';
import React from 'react';
import { FaPaperclip } from 'react-icons/fa';
import { FaChevronUp } from 'react-icons/fa';
import { HashLink } from 'react-router-hash-link';

export default function AboutMe() {
  const CertificateList = [
    {
      id: 1,
      title: 'Foundations of User Experience (UX) Design',
      image: 'images/cert1.png',
      link: 'https://coursera.org/share/42bc00d6af66b96f1f6b2aedd3cfddc3',
    },
    {
      id: 2,
      title: 'Start the UX Design Process: Empathize, Define, and Ideate',
      image: 'images/cert2.png',
      link: 'https://coursera.org/share/f2c6d4b758f46ef15430795b0f8f5dc0',
    },
    {
      id: 3,
      title: 'Build Wireframes and Low-Fidelity Prototypes',
      image: 'images/cert3.png',
      link: 'https://coursera.org/share/0e603d86f7832b8bdd65b5792cbb2c8f',
    },
    {
      id: 4,
      title: 'Conduct UX Research and Test Early Concepts',
      image: 'images/cert4.JPEG',
      link: 'https://coursera.org/share/7ae76bcc9bcd7843936fbca5ceb4ec35',
    },
    {
      id: 5,
      title: 'Create High-Fidelity Designs and Prototypes in Figma',
      image: 'images/cert5.JPEG',
      link: 'https://coursera.org/share/b6302a2eb321b64fc613adf3ac7c90e7',
    },

    {
      id: 6,
      title: 'Responsive Web Design in Adobe XD',
      image: 'images/cert6.png',
      link: 'https://coursera.org/share/07404854bfb4ee5159bb3f5368e2cf7d',
    },

  ];

  return (
    <Container
      gap={0}
      fluid
      css={{ paddingTop: '20vh', backgroundColor: '$bedge' }}
    >
      <Container sm>
        <Grid.Container css={{ rowGap: '1rem' }}>
          {/* Introdcution */}

          <Grid xs={12} sm={5}>
            <Row align='center'>
              <Col>
                <Text h1 className='over-topic'>
                  My name is
                </Text>
                <Text h1 className='aboutme-topic'>
                  Kamin Prakob &#128521;
                </Text>
                <Spacer y={2} />
                <Text>
                  I recently graduated from King Mongkut's University of
                  Technology Thonburi (KMUTT) majoring in Computer Engineering.
                </Text>
                <Spacer y={2} />
                <Text>
                  This website displays my related user experience and user
                  interface experiences. It is also expected that the design
                  will minimize pain points and improve users' daily lives!
                </Text>
                <Spacer y={2} />
                <Container gap={0}>
                  <Row justify='flex-end'>
                    <a
                      href='https://drive.google.com/file/d/12fSFt-aCxgd5updKOyGjbvm5bQ2cwsbW/view?usp=sharing'
                      style={{ textDecoration: 'none' }}
                    >
                      <Button
                        auto
                        icon={<FaPaperclip />}
                        css={{
                          fontSize: '1rem',
                          letterSpacing: '0.05rem',
                        }}
                      >
                        Resume
                      </Button>
                    </a>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Grid>
          <Grid xs={12} sm={1}></Grid>

          {/* Image Group */}

          <Grid.Container xs={12} sm={6}>
            <Row align='center' css={{ gap: '1rem' }}>
              <Col>
                <Image
                  showSkeleton
                  src='images/KaminPrakob.jpg'
                  height='100%'
                  width='100%'
                />
              </Col>
              <Col>
                <Image showSkeleton src='images/kamin2022.jpg' />
                <Spacer y={1} />
                <Image showSkeleton src='images/PiezoPlaza.jpeg' />
              </Col>
            </Row>
          </Grid.Container>
        </Grid.Container>
      </Container>
      <Spacer y={4} />
      <Container sm>
        <Row>
          <Col>
            <Text h2>Certificate</Text>
            <Text h5 color='$gray'>
              Click the thumbnail to view the certificate
            </Text>
          </Col>
        </Row>
        <Spacer y={2} />

        {/* Certificate */}

        <Grid.Container gap={2}>
          <Grid xs={12} sm={6} md={4}>
            <a href={CertificateList[0].link}>
              <Card hoverable clickable cover>
                <Card.Image src='images/cert1.png' />
                <Card.Footer>
                  <Col>
                    <Text h5>Google UX Certificate</Text>
                    <Text>Foundations of User Experience (UX) Design</Text>
                  </Col>
                </Card.Footer>
              </Card>
            </a>
          </Grid>
          <Grid xs={12} sm={6} md={4}>
            <a href={CertificateList[1].link}>
              <Card hoverable clickable cover>
                <Card.Image src='images/cert2.png' />
                <Card.Footer>
                  <Col>
                    <Text h5>Google UX Certificate</Text>
                    <Text>
                      Start the UX Design Process: Empathize, Define, and Ideate
                    </Text>
                  </Col>
                </Card.Footer>
              </Card>
            </a>
          </Grid>
          <Grid xs={12} sm={6} md={4}>
            <a href={CertificateList[2].link}>
              <Card hoverable clickable cover>
                <Card.Image src='images/cert3.png' />
                <Card.Footer>
                  <Col>
                    <Text h5>Google UX Certificate</Text>
                    <Text>Build Wireframes and Low-Fidelity Prototypes</Text>
                  </Col>
                </Card.Footer>
              </Card>
            </a>
          </Grid>
          <Grid xs={12} sm={6} md={4}>
            <a href={CertificateList[3].link}>
              <Card hoverable clickable cover>
                <Card.Image src='images/cert4.png' />
                <Card.Footer>
                  <Col>
                    <Text h5>Google UX Certificate</Text>
                    <Text>Conduct UX Research and Test Early Concepts</Text>
                  </Col>
                </Card.Footer>
              </Card>
            </a>
          </Grid>
          <Grid xs={12} sm={6} md={4}>
            <a href={CertificateList[4].link}>
              <Card hoverable clickable cover>
                <Card.Image src='images/cert5.png' />
                <Card.Footer>
                  <Col>
                    <Text h5>Google UX Certificate</Text>
                    <Text>
                      Create High-Fidelity Designs and Prototypes in Figma
                    </Text>
                  </Col>
                </Card.Footer>
              </Card>
            </a>
          </Grid>

          <Grid xs={12} sm={6} md={4}>
            <a href={CertificateList[5].link}>
              <Card hoverable clickable cover>
                <Card.Image src='images/cert6.png' />
                <Card.Footer>
                  <Col>
                    <Text h5>Google UX Certificate</Text>
                    <Text>Responsive Web Design in Adobe XD</Text>
                  </Col>
                </Card.Footer>
              </Card>
            </a>
          </Grid>
        </Grid.Container>
      </Container>
      <Spacer y={4} />

      {/* Extracurricular Activities */}

      <Container md>
        <Text h2 css={{ textAlign: 'center' }}>
          Extracurricular Activities
        </Text>
        <Spacer y={2} />

        {/* Comcamp */}

        <Container css={{ borderRadius: '$base' }}>
          <Card shadow={false} css={{ padding: '2rem' }}>
            <Row justify='center'>
              <Text h3>KMUTT Comcamp 31st</Text>
            </Row>
            <Spacer y={1} />

            <Row justify='center'>
              <Text align='center'>
                Educational camp for high school students that are interested in
                becoming knowledgeable computer engineers by thinking,
                designing, and applying knowledge into practice in the areas of
                software, hardware, and networking of the computer system.
              </Text>
            </Row>
            <Spacer y={1} />
            <Grid.Container gap={0} justify='center' css={{ rowGap: '1rem' }}>
              <Grid xs={12} sm={3}>
                <Row justify='center' align='center'>
                  <Text h5>Period: &nbsp;</Text>
                  <Text align='center'> 15 - 19 April 2019</Text>
                </Row>
              </Grid>
              <Grid xs={12} sm={6}>
                <Row justify='center' align='center'>
                  <Text h5>Location: &nbsp;</Text>
                  <Text align='center'>
                    {' '}
                    King Mongkuy's University of Technology Thonburi (KMUTT)
                  </Text>
                </Row>
              </Grid>
              <Grid xs={12} sm={3}>
                <Row justify='center' align='center'>
                  <Text h5>Role: &nbsp;</Text>
                  <Text align='center'> Head of wellfare section.</Text>
                </Row>
              </Grid>
            </Grid.Container>
          </Card>

          <Spacer y={1} />

          <Grid.Container gap={2}>
            <Grid xs={12} sm={4}>
              <Image
                showSkeleton
                src='images/comcamp/DSC_0265.jpg'
                height='100%'
                width='100%'
              />
            </Grid>
            <Grid xs={12} sm={4}>
              <Image
                showSkeleton
                src='images/comcamp/DSC_0005.jpg'
                height='100%'
                width='100%'
              />
            </Grid>
            <Grid xs={12} sm={4}>
              <Image
                showSkeleton
                src='images/comcamp/DSC_0150.jpg'
                height='100%'
                width='100%'
              />
            </Grid>
          </Grid.Container>
        </Container>

        <Spacer y={2} />

        {/* CPE Rsa8 */}

        <Container css={{ borderRadius: '$base' }}>
          <Card shadow={false} css={{ padding: '2rem' }}>
            <Row justify='center'>
              <Text h3>KMUTT CPE RSA 8th</Text>
            </Row>
            <Spacer y={1} />

            <Row justify='center'>
              <Text align='center'>
                Volunteer to help the schools that lack technological tools,
                knowledge, and necessities, and include repairing education
                building.
              </Text>
            </Row>
            <Spacer y={1} />
            <Grid.Container gap={0} justify='center' css={{ rowGap: '1rem' }}>
              <Grid xs={12} sm={3}>
                <Row justify='center' align='center'>
                  <Text h5>Period: &nbsp;</Text>
                  <Text align='center'> 15 - 19 April 2019</Text>
                </Row>
              </Grid>
              <Grid xs={12} sm={6}>
                <Row justify='center' align='center'>
                  <Text h5>Location: &nbsp;</Text>
                  <Text align='center'>
                    Ban Phai Kok Wua School, Nakornpathom
                  </Text>
                </Row>
              </Grid>
              <Grid xs={12} sm={3}>
                <Row justify='center' align='center'>
                  <Text h5>Role: &nbsp;</Text>
                  <Text align='center'>Volunteer</Text>
                </Row>
              </Grid>
            </Grid.Container>
          </Card>

          <Spacer y={1} />

          <Grid.Container gap={2}>
            <Grid xs={12}>
              <Image src='images/rsa/Ending.jpg' height='100%' width='100%' />
            </Grid>
            <Grid xs={12} sm={6}>
              <Image src='images/rsa/IMG_2322.JPG' height='100%' width='100%' />
            </Grid>
            <Grid xs={12} sm={6}>
              <Image
                src='images/rsa/painting_wall.jpg'
                height='100%'
                width='100%'
              />
            </Grid>
          </Grid.Container>
        </Container>
      </Container>
      <Spacer y={2} />
      <Row justify='center'>
        <HashLink to='/aboutme#top' smooth>
          <Button id='backToTop' auto light iconRight={<FaChevronUp />}>
            Back to top
          </Button>
        </HashLink>
      </Row>
      <Spacer y={2} />
    </Container>
  );
}
