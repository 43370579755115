import {
  Avatar,
  Button,
  Col,
  Container,
  Grid,
  Image,
  Row,
  Spacer,
  Text,
} from '@nextui-org/react';
import React from 'react';
import { FaChevronLeft, FaChevronUp, FaRegCommentDots } from 'react-icons/fa';
import { MdAccessible, MdDesignServices } from 'react-icons/md';
import { HiOutlineLightBulb } from 'react-icons/hi';
import TextCard from '../components/TextCard';
import EnumCard from '../components/EnumCard';
import { HashLink } from 'react-router-hash-link';

export default function Menual() {
  return (
    <div className=''>
      <Container sm css={{ paddingTop: '15vh' }}>
        <Row>
          <HashLink to='/#top'>
            <Avatar
              pointer
              color='$black'
              icon={<FaChevronLeft color='white' />}
              squared
              css={{ backgroundColor: '$primary' }}
            />
          </HashLink>
        </Row>
        <Spacer y={1} />
        <Row>
          <Col>
            <Text h1>Menual</Text>
            <Text h4 color='$gray'>
              A food delivery app that feel like order at the restaurant.
            </Text>
          </Col>
        </Row>
        <Spacer y={2} />
        <Row>
          <Image
            alt=''
            src='/images/Menual/Menual_Hero.png'
            width='100%'
            height='100%'
          />
        </Row>
        <Spacer y={2} />

        <Grid.Container css={{ rowGap: '1rem' }}>
          <Grid xs={12} sm={4}>
            <Container gap={0}>
              <Row>
                <Col>
                  <Text h4>Duration:</Text>
                  <Spacer y={0.5} />
                  <Text css={{ fontFamily: '$primary' }}>
                    March 2022 - July 2022
                  </Text>
                </Col>
              </Row>
            </Container>
          </Grid>
          <Grid xs={12} sm={4}>
            <Container gap={0}>
              <Row>
                <Col>
                  <Text h4>Project info:</Text>
                  <Spacer y={0.5} />
                  <Text css={{ fontFamily: '$primary' }}>
                    Google UX Design Professional Certificate course prompt
                    project
                  </Text>
                </Col>
              </Row>
            </Container>
          </Grid>
          <Grid xs={12} sm={4}>
            <Container gap={0}>
              <Row>
                <Col>
                  <Text h4>Tools:</Text>
                  <Spacer y={0.5} />

                  <Text css={{ fontFamily: '$primary' }}>Figma and Maze</Text>
                </Col>
              </Row>
            </Container>
          </Grid>
        </Grid.Container>

        <Spacer y={2} />
        <Row>
          <Text h2>Overview</Text>
        </Row>
        <Spacer y={2} />
        <Row>
          <Text
            css={{
              fontFamily: '$primary',
              lineHeight: '$xl',
            }}
          >
            Menual is a food delivery application that can use everywhere. The
            application created for people that want to order food or meal that
            feel the same as onsite ordering as much as possible by
            customization feature that include in the app.
          </Text>
        </Row>

        <Spacer y={2} />
        <Grid.Container gap={2}>
          <Grid xs={12} md={6}>
            <TextCard
              headline='Role'
              content='As a UX designer of the project.'
            />
          </Grid>
          <Grid xs={12} md={6}>
            <TextCard
              headline='Responsibilities'
              content='Conducting interviews, paper and digital wireframing, low and
            high-fidelity prototyping, conducting usability studies, and
            iterating on designs.'
            />
          </Grid>
        </Grid.Container>
      </Container>

      {/* Design Process */}
      <Container sm css={{ padding: '3rem 0' }}>
        <Container lg gap={2}>
          <Row>
            <Avatar
              color={''}
              icon={<FaRegCommentDots size='auto' color='white' />}
              size='xl'
              css={{ backgroundColor: '$green500' }}
            />
            <Spacer x={1} />

            <Col>
              <Text h5 color='$gray'>
                USER INSIGHTS
              </Text>
              <Text h2>Understanding the user</Text>
            </Col>
          </Row>

          <Spacer y={2} />

          {/* User Research */}

          <Text
            css={{
              fontFamily: '$primary',
              lineHeight: '$xl',
            }}
          >
            I conducted interviews with five university students, summarized
            their responses in notes, and grouped similar types of user
            insights.
          </Text>
          <Spacer y={0.5} />
          <Text
            css={{
              fontFamily: '$primary',
              lineHeight: '$xl',
            }}
          >
            According to interview summaries, the user group's main complaint is
            that the application they are using does not allow them to customize
            items as much as they would like. They also mention other minor
            issues such as nearby restaurants not being dominant, it being
            difficult to remove items from orders, and so on.
          </Text>

          <Spacer y={2} />

          <Text h4>Pain points</Text>

          <Spacer y={2} />

          <Grid.Container gap={2}>
            <Grid xs={12} sm={6}>
              <EnumCard
                number='1'
                content='Users cannot more customize to get exact order that they want.'
                bgColor=''
                header='Lack of options'
              />
            </Grid>

            <Grid xs={12} sm={6}>
              <EnumCard
                number='2'
                content='After add items to order, they struggling on edit or remove items from order.'
                bgColor=''
                header='Order management'
              />
            </Grid>
          </Grid.Container>

          <Spacer y={2} />

          <Text h4>Personas</Text>

          <Spacer y={2} />

          <Image
            css={{ borderRadius: '1rem' }}
            src='images/Menual/Persona.png'
            width='100%'
            height='100%'
            alt=''
          />

          <Spacer y={2} />

          <Text h4>Sakchai's journey map</Text>

          <Spacer y={2} />

          <Image
            css={{ borderRadius: '1rem' }}
            src='images/Menual/User_journey.png'
            width='100%'
            height='100%'
            alt=''
          />
        </Container>
      </Container>
      {/* Wireframing */}
      <Container md>
        <Grid.Container gap={2} alignItems='center' css={{ rowGap: '3rem' }}>
          <Grid xs={12}>
            <Container fluid gap={0}>
              <Row>
                <Avatar
                  color={''}
                  icon={<HiOutlineLightBulb size='auto' color='white' />}
                  size='xl'
                  css={{ backgroundColor: '$yellow500' }}
                />
                <Spacer x={1} />
                <Col>
                  <Text h5 color='$gray'>
                    IDEATE
                  </Text>
                  <Text h1>Starting the design</Text>
                </Col>
              </Row>
              <Spacer y={1} />

              {/* Paper wireframes */}

              <Row>
                <Text
                  css={{
                    fontFamily: '$primary',
                    lineHeight: '$xl',
                  }}
                >
                  Create the initial design by draft wireframes in various
                  elements from ideas, then highlighted the element that
                  commonly show up in wireframe. Also, all of wireframes would
                  be convert to digital wireframes for further steps.
                </Text>
              </Row>
              <Spacer y={2} />

              <Row justify='center'>
                <Text h3>Paper wireframes</Text>
              </Row>

              <Spacer y={2} />

              <Row justify='center'>
                <Image
                  width='80%'
                  height='100%'
                  alt=''
                  src='images/Menual/Paper_wireframe.png'
                />
              </Row>

              <Spacer y={2} />

              <Row justify='center'>
                <Text h3>Digital wireframes</Text>
              </Row>

              {/* Homepage as digital wireframes */}

              <Row>
                <Grid.Container gap={2} alignItems='center'>
                  <Grid xs={12} md={6}>
                    <Text
                      css={{
                        fontFamily: '$primary',
                        lineHeight: '$xl',
                      }}
                    >
                      In initial of design phase continued, I created a homepage
                      that contain element like hero image, nearby restaurant
                      section based on user research and also navigation bar for
                      making user go through the app easily.
                    </Text>
                  </Grid>
                  <Grid xs={12} md={6}>
                    <Image
                      width='100%'
                      height='100%'
                      alt=''
                      src='images/Menual/Wireframe1.png'
                    />
                  </Grid>
                </Grid.Container>
              </Row>

              <Spacer y={2} />

              {/* Customization page as digital wireframes */}

              <Row>
                <Grid.Container gap={2} alignItems='center'>
                  <Grid xs={12} md={6}>
                    <Text
                      css={{
                        fontFamily: '$primary',
                        lineHeight: '$xl',
                      }}
                    >
                      The customization page, giving users abilities to change
                      food properties in varieties option and when users done
                      with customization, they can notice how the price changed.
                    </Text>
                  </Grid>
                  <Grid xs={12} md={6}>
                    <Image
                      width='100%'
                      height='100%'
                      alt=''
                      src='images/Menual/Wireframe2.png'
                    />
                  </Grid>
                </Grid.Container>
              </Row>

              <Spacer y={2} />

              {/* Usability studies */}

              <Row justify='center'>
                <Text h3>Low-fidelity prototype usability study</Text>
              </Row>

              <Spacer y={1} />

              <Row justify='center'>
                <Text>
                  After create low-fi prototype, I've conducted usability study
                  to evalute initial solution and design.
                </Text>
              </Row>
              {/* Low-fidelity Finding */}
              <Spacer y={2} />

              <Grid.Container gap={2}>
                <Grid xs={12} md={6}>
                  <Spacer y={2} />

                  <Image
                    width='100%'
                    height='100%'
                    src='images/Menual/Low_fi_prototype.png'
                  />
                </Grid>
                <Grid xs={12} md={6}>
                  <Container gap='0'>
                    <Row justify='center'>
                      <Text h5 color='$gray'>
                        MAIN FINDINGS
                      </Text>
                    </Row>

                    <Spacer y={1} />

                    <Grid.Container gap={2}>
                      <Grid xs={12} md={6}>
                        <EnumCard
                          number='1'
                          content='User need to see restaurant first in search result'
                        />
                      </Grid>
                      <Grid xs={12} md={6}>
                        <EnumCard
                          number='2'
                          content='Users unable to remove items in order.'
                        />
                      </Grid>
                      <Grid xs={12} md={6}>
                        <EnumCard
                          number='3'
                          content='Not everyone to see blank page when search result
                        result'
                        />
                      </Grid>
                      <Grid xs={12} md={6}>
                        <EnumCard
                          number='4'
                          content='Not everyone to see customized indicator when item
                        customized.'
                        />
                      </Grid>
                    </Grid.Container>
                  </Container>
                </Grid>
              </Grid.Container>

              <Spacer y={2} />
            </Container>
          </Grid>
        </Grid.Container>
      </Container>

      <Spacer y={4} />
      {/* Mockup & Design System  */}
      <Container md>
        <Row justify='center'>
          <Avatar
            color={''}
            icon={<MdDesignServices size='auto' color='white' />}
            size='xl'
            css={{ backgroundColor: '$red600' }}
          />
        </Row>
        <Spacer y={1} />
        <Row justify='center'>
          <Text h5 color='$gray'>
            DESIGN
          </Text>
        </Row>
        <Row justify='center'>
          <Text h1>Mock up & design system</Text>
        </Row>

        <Spacer y={4} />

        {/* Mockup hero */}

        {/* <Row>
          <Grid.Container gap={1.5}>
            <Image
              css={{
                minWidth: '240px',
                height: 'auto',
              }}
              src='images/Menual/Mockup_hero1.png'
              alt=''
            />
          </Grid.Container>
        </Row> */}
        <Row justify='center'>
          <Text h4>Design system</Text>
        </Row>
        <Spacer y={2} />
        <Row>
          <Image
            css={{ borderRadius: '1rem' }}
            src='images/Menual/Design_System.png'
            width='100%'
            height='100%'
            alt=''
          />
        </Row>

        <Spacer y={2} />

        <Container md gap={1}>
          <Row justify='center'>
            <Text h4>Improvement from feedbacks</Text>
          </Row>
          <Spacer y={2} />

          {/* First improvement */}

          <Row justify='center'>
            <Text css={{ textAlign: 'center' }}>
              At the first design, I design 2 buttons for add default dish
              immediately to order and customize button for custom it. But after
              usability study, I changed the way to order food by there will be
              a pop-up to customize (if not, just add dish to order) based on
              users feedback.
            </Text>
          </Row>

          <Spacer y={2} />

          <Row justify='center'>
            <Image
              width={750}
              height='100%'
              src='images/Menual/Improvement_1.png'
              objectFit='cover'
            />
          </Row>
          <Spacer y={2} />

          {/* 2nd Improvement */}

          <Row justify='center'>
            <Text css={{ textAlign: 'center' }}>
              Also, there are feedback about the search page. Users said that
              there should be something to show for them when they want to
              search restaurant/dishes like suggestion.
            </Text>
          </Row>

          <Spacer y={2} />

          <Row justify='center'>
            <Image
              width={750}
              height='100%'
              src='images/Menual/Improvement_2.png'
              objectFit='cover'
            />
          </Row>
          <Spacer y={2} />

          {/* 3rd Improvement */}

          <Row justify='center'>
            <Text css={{ textAlign: 'center' }}>
              In second usability study, most of users notice that location bar
              is search bar due to their experiences. So, I updated by show
              location that located for 1200ms then shrink it to show only icon
              and after that, show search bar instead.
            </Text>
          </Row>

          <Spacer y={2} />

          <Row justify='center'>
            <Image
              width={750}
              height='100%'
              src='images/Menual/Improvement_4.png'
              objectFit='cover'
            />
          </Row>
          <Spacer y={2} />

          {/* 4th Improvement */}

          <Row justify='center'>
            <Text css={{ textAlign: 'center' }}>
              Lastly, users told that it took too much time to change dishes
              amount. So, I updated the mockup to give users ability to easily
              change dish amount.
            </Text>
          </Row>

          <Spacer y={2} />

          <Row justify='center'>
            <Image
              width={750}
              height='100%'
              src='images/Menual/Improvement_6.png'
              objectFit='cover'
            />
          </Row>
          <Spacer y={2} />

          <Spacer y={2} />

          {/* Usability studies */}

          <Row justify='center'>
            <Text h3>Usability studies</Text>
          </Row>

          <Spacer y={1} />

          <Row justify='center'>
            <Text>
              After create design and apply improvement from low-fidelity
              usability study feedbacks, <br /> I've conduct hi-fidelity
              usability to evalute visual design and final features that
              included.
            </Text>
          </Row>
          <Spacer y={2} />

          {/* High-fidelity Finding */}

          <Row>
            <Text h4>High-fidelity prototype usability study</Text>
          </Row>
          <Spacer y={2} />

          <Grid.Container gap={2}>
            <Grid xs={12} md={6}>
              <Spacer y={2} />

              <Image
                width='100%'
                height='100%'
                src='images/Menual/Hi_fi_prototype.png'
              />
            </Grid>
            <Grid xs={12} md={6}>
              <Container gap='0'>
                <Row justify='center'>
                  <Text h5 color='$gray'>
                    MAIN FINDINGS
                  </Text>
                </Row>

                <Spacer y={1} />

                <Grid.Container gap={2}>
                  <Grid xs={12}>
                    <EnumCard
                      number='1'
                      content='Misunderstanding what “custom order” button mean?'
                    />
                  </Grid>
                  <Grid xs={12} md={6}>
                    <EnumCard
                      number='2'
                      content='Not everyone to see blank page when search result
                        result'
                    />
                  </Grid>
                  <Grid xs={12} md={6}>
                    <EnumCard
                      number='3'
                      content='Difficult to decrease/increase dishes amount.'
                    />
                  </Grid>
                </Grid.Container>
              </Container>
            </Grid>
          </Grid.Container>
        </Container>
      </Container>

      {/* Ending */}

      <Spacer y={2} />

      {/* Accessibilities consideration */}
      <Container sm>
        <Row align='center'>
          <Avatar
            color={''}
            icon={<MdAccessible size='xl' color='white' />}
            size='xl'
            css={{ backgroundColor: '$blue400' }}
          />

          <Spacer x={1} />

          <Text h2> Accessibility considerations</Text>
        </Row>

        <Spacer y={2} />

        <Row>
          <Grid.Container gap={3}>
            <Grid xs={12} sm={6}>
              <EnumCard
                header='Iconography'
                number='1'
                content='Using iconography to help people easily understand what
                      element is and what functional it can be action.'
              />
            </Grid>
            <Grid xs={12} sm={6}>
              <EnumCard
                header='Readable contras ratio for text'
                number='2'
                content='Contrast ratio of text and background more than 4.5 point
                      for better readability (According to the WCAG).'
              />
            </Grid>
          </Grid.Container>
        </Row>
      </Container>

      <Spacer y={2} />

      <Container sm>
        <Text h3>Takeaways</Text>
        <Spacer y={2} />

        <Text h4> Impact</Text>
        <Spacer y={1} />

        <Text
          css={{
            fontFamily: '$primary',
            lineHeight: '$xl',
          }}
        >
          The application improve users experiences from previous apps that
          they’re using. But it still not answer all of the user that I studied.
          So,I've to learn more to design better solutions for users.
        </Text>
        <Spacer y={2} />

        <Text h4> What I learned</Text>
        <Spacer y={1} />

        <Grid.Container gap={2}>
          <Grid xs={12} sm={6}>
            <EnumCard
              number='1'
              header='Design system'
              content={
                "I've used design system when I was an intern. But in this project, I created my own design system from scratch and learn what component should included to create more consistency design overall the application."
              }
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <EnumCard
              number='2'
              header='User experiences design'
              content={
                'Google UX Profession certificate program enhance my knowledge about how correctly UX design process have been.'
              }
            />
          </Grid>
        </Grid.Container>

        <Spacer y={2} />

        <Row justify='center'>
          <HashLink to='/menual#top'>
            <Button id='backToTop' auto light iconRight={<FaChevronUp />}>
              Back to top
            </Button>
          </HashLink>
        </Row>
        <Spacer y={2} />
      </Container>
    </div>
  );
}
